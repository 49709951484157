<template>
  <div class="overview">
    <NavBer></NavBer>
    <div class="bg1">
        <img src="../assets/images/dynamic/24.png" alt="">
    </div>
    <div class="w content">
      <el-row>
        <el-col :span="24"
          ><div class="grid-content ">
               <div class="profile">
                 <h2>公司简介</h2>
                 <h3>Company profile</h3>
                 <p style="text-align: left;">主营业务为：药品、医疗器械、保健食品、消杀产品 批发销售，经营品类8000余种。面向药品批发企业、零售药店、医疗机构开展药械配送业务，销售网络覆盖冀南地区。</p>
                 <!-- <span>白塔寺药店始创于1872年</span> -->
               </div>
            </div
        ></el-col>
      </el-row>
      <el-row>
        <el-col :span="24"
          ><div class="grid-content ">
               <div class="culture">
                 <h2>企业文化</h2>
                 <h3>Corporate culture</h3>
                 <div class="contenttext">企业精神：诚信  敬业  团结  创新</div>
                 <div class="contenttext">经营方针：顾客至上，质量第一</div>
                 <div class="contenttext">企业作风：勤奋忠诚，严谨高效，热情自信</div>
                 <div class="contenttext">企业使命：为客户创造价值 为大众健康护航</div>
                 <div class="contenttext">企业愿景：让天宇医药成为家喻户晓的健康品牌</div>
               </div>
            </div
        ></el-col>
      </el-row>
      
    </div>
    <Footbar></Footbar>
  </div>
</template>

<script>
import NavBer from "../components/nav";
import Footbar from "../components/footbar";
export default {
  components: {
    NavBer,
    Footbar,
  },
};
</script>

<style scoped>
.overview {
  padding-top: 90px;
}
.bg1 {
  width: 100%;
  height: 400px;
  margin-bottom: 10px;
}
.bg1 img {
  width: 100%;
  height: 100%;
}
.content {
  margin-top: 60px;
}
.profile,
.culture{
  width: 100%;
  /* height: 300px; */
 text-align: center;
 background: -webkit-linear-gradient(top left,#F0FF00,#58CFFB);
 padding-top: 50px;
 padding-bottom: 100px;
 
}
.profile h2 {
  color: #333;
}
.profile h3 {
  color: #130CB7;
}
.profile p{
  
  width: 400px;
  margin: 30px auto 10px;
}

.culture {
 background: -webkit-linear-gradient(top left,#43CBFF,#9708CC);
}
.culture h2 {
  color: #333;
}
.culture h3 {
  color: #130CB7;
}
.culture p{
  
  width: 400px;
  margin: 30px auto 10px;
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.grid-content {
  border-radius: 4px;
  min-height: 300px;
 
}
.contenttext{
  width: 500px;
  margin: 30px auto 10px;
  color: #fff;
  text-align: left;
  padding-left: 200px;
  
  }
</style>
